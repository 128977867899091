import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "bonuses"

class Bonus{
    constructor(id, period, individual, global){
        this.id = id
        this.period = period
        this.individual = individual
        this.global = global
    }

    static async initializeOne(periodId){
        let tmp_bonus = new Bonus(null, periodId, {}, [])
        return tmp_bonus
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let bonuses = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_bonus = new Bonus(doument.id, data.period, data.individual, data.global)
            bonuses.push(tmp_bonus)
        })

        return bonuses
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let bonus = new Bonus(response.id, data.period, data.individual, data.global)

        return bonus
    }

    static async getByPeriod(periodId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId))
        let response = await getDocs(documentQuery)

        let bonuses = []

        response.forEach(document => {
            let data = document.data()
            let tmp_bonus = new Bonus(document.id, data.period, data.individual, data.global)
            bonuses.push(tmp_bonus)
        })

        return bonuses
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let bonuses = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_bonus = new Bonus(document.id, data.period, data.individual, data.global)
                bonuses.push(tmp_bonus)
            })
            
            if(callback != null){
                callback(bonuses)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let bonus = new Bonus(snapshot.id, data.period, data.individual, data.global)
            
            if(callback != null){
                callback(bonus)
            }
        })
        return unsub
    }

    static async listenByPeriod(periodId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let bonuses = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_bonus =  new Bonus(document.id, data.period, data.individual, data.global)
                bonuses.push(tmp_bonus)
            })
            
            if(callback != null){
                callback(bonuses)
            }
        })
        return unsub
    } 

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                period : this.period,
                individual : this.individual,
                global : this.global,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                period : this.period,
                individual : this.individual,
                global : this.global,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Bonus