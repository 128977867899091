<template>
  <span v-if="profile.role != 'User'">
    <v-card>
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Bonus/Malus</h1>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-row align-center justify-center">
          <v-col cols="12" md="4" v-show="profile.role != 'User'">
            <v-select
              :items="sessions"
              item-text="name"
              return-object
              label="Sélectionnez une session"
              v-model="selectedSession"
              @change="OnSessionSelected"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="selectedSession != null"
            v-show="profile.role != 'User'"
          >
            <v-select
              :items="teams"
              item-text="name"
              return-object
              label="Sélectionnez une équipe"
              v-model="selectedTeam"
              @change="OnSelection"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="4" v-if="selectedSession != null">
            <v-select
              :items="periods"
              item-text="name"
              return-object
              label="Sélectionnez une période"
              v-model="selectedPeriod"
              @change="OnSelection"
            ></v-select>
          </v-col>
        </v-row>
        <span class="mt-3 pa-3" v-if="currentBonus != null">
          <v-row class="d-flex flex-row align-center justify-center">
            <v-col
              cols="12"
              class="d-flex flex-column align-center justify-center"
            >
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                @click="openBonusDialog"
                >Ajouter un bonus/malus</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row align-start justify-center">
            <v-col cols="12" md="6">
              <v-card outlined>
                <v-card-title>
                  <h3>Produits :</h3>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li
                      v-for="bonusType in bonusTypes"
                      v-if="bonusType.type == 'products'"
                    >
                      <h2 class="py-3" v-text="bonusType.title"></h2>

                      <div
                        v-if="
                          currentBonus.individual[selectedTeam.id] !=
                            undefined &&
                          currentBonus.global
                            .concat(currentBonus.individual[selectedTeam.id])
                            .filter(
                              (bonus) => bonus.type.title == bonusType.title
                            ).length > 0
                        "
                      >
                        <v-card
                          class="ma-2"
                          v-for="bonus in currentBonus.global
                            .concat(currentBonus.individual[selectedTeam.id])
                            .filter(
                              (bonus) => bonus.type.title == bonusType.title
                            )"
                        >
                          <v-card-text>
                            <div
                              class="d-flex flex-row align-center justify-space-between flex-wrap"
                            >
                              <span>
                                <h3
                                  class="pb-2"
                                  v-text="bonus.description"
                                ></h3>
                                <h2
                                  class="success--text"
                                  v-text="
                                    '+ ' +
                                    dataFormater.formatMoney(
                                      parseFloat(bonus.value)
                                    )
                                  "
                                ></h2>
                                <h4
                                  :class="
                                    bonus.scope == 'session'
                                      ? 'warning--text pb-2'
                                      : 'accent--text pb-2'
                                  "
                                  v-text="
                                    bonus.scope == 'session'
                                      ? '(Bonus de session)'
                                      : '(Bonus d\'équipe)'
                                  "
                                ></h4>
                              </span>
                              <span>
                                <v-btn color="error" icon>
                                  <v-icon @click="deleteBonus(bonus)"
                                    >mdi-delete-outline</v-icon
                                  >
                                </v-btn>
                              </span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                      <div v-else>Aucun produit pour l'instant</div>
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card outlined>
                <v-card-title>
                  <h3>Charges :</h3>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li
                      v-for="bonusType in bonusTypes"
                      v-if="bonusType.type == 'charges'"
                    >
                      <h2 class="py-3" v-text="bonusType.title"></h2>
                      <div
                        v-if="
                          currentBonus.individual[selectedTeam.id] !=
                            undefined &&
                          currentBonus.global
                            .concat(currentBonus.individual[selectedTeam.id])
                            .filter(
                              (bonus) => bonus.type.title == bonusType.title
                            ).length > 0
                        "
                      >
                        <v-card
                          class="ma-2"
                          v-for="bonus in currentBonus.global
                            .concat(currentBonus.individual[selectedTeam.id])
                            .filter(
                              (bonus) => bonus.type.title == bonusType.title
                            )"
                        >
                          <v-card-text>
                            <div
                              class="d-flex flex-row align-center justify-space-between flex-wrap"
                            >
                              <span>
                                <h3
                                  class="pb-2"
                                  v-text="bonus.description"
                                ></h3>
                                <h2
                                  class="error--text"
                                  v-text="
                                    '- ' +
                                    dataFormater.formatMoney(
                                      parseFloat(bonus.value)
                                    )
                                  "
                                ></h2>
                                <h4
                                  :class="
                                    bonus.scope == 'session'
                                      ? 'warning--text pb-2'
                                      : 'accent--text pb-2'
                                  "
                                  v-text="
                                    bonus.scope == 'session'
                                      ? '(Malus de session)'
                                      : '(Malus d\'équipe)'
                                  "
                                ></h4>
                              </span>
                              <span>
                                <v-btn color="error" icon>
                                  <v-icon @click="deleteBonus(bonus)"
                                    >mdi-delete-outline</v-icon
                                  >
                                </v-btn>
                              </span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                      <div v-else>Aucune charge pour l'instant</div>
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </span>
      </v-card-text>
    </v-card>
    <v-dialog v-model="addBonusDialog" scrollable persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Ajouter un Bonus/Malus</v-card-title>
        <v-card-text>
          <v-select
            :items="bonusScopes"
            v-model="selectedScope"
            item-text="title"
            return-object
            label="Destinataire"
          ></v-select>
          <v-select
            :items="bonusTypes"
            v-model="selectedType"
            item-text="title"
            return-object
            label="Type"
          ></v-select>
          <v-text-field
            label="Description"
            v-model="createDescription"
          ></v-text-field>
          <v-text-field
            type="number"
            label="Valeur"
            v-model="createValue"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="addBonusDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="addBonus">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import Session from "@/classes/Session";
import Univers from "@/classes/Univers";
import Period from "@/classes/Period";
import Team from "@/classes/Team";
import Bonus from "@/classes/Bonus";

import dataFormater from "@/assets/functions/dataFormater.js";

import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  name: "Bonuses",
  props: ["user", "profile"],
  setup() {
    return {
      dataFormater,
    };
  },
  data() {
    return {
      unsub: [],
      sessions: [],
      teams: [],
      periods: [],

      addBonusDialog: false,

      bonusTypes: [
        {
          title: "Produits d'exploitation",
          type: "products",
          subType: "exploitation",
        },
        {
          title: "Produits financiers",
          type: "products",
          subType: "financial",
        },
        {
          title: "Produits exceptionnels",
          type: "products",
          subType: "exceptional",
        },
        {
          title: "Charges d'exploitation",
          type: "charges",
          subType: "exploitation",
        },
        {
          title: "Charges financières",
          type: "charges",
          subType: "financial",
        },
        {
          title: "Charges exceptionnelles",
          type: "charges",
          subType: "exceptional",
        },
      ],
      bonusScopes: [
        {
          title: "Session",
          type: "session",
        },
        {
          title: "Équipe",
          type: "team",
        },
      ],

      selectedSession: null,
      selectedTeam: null,
      selectedPeriod: null,

      selectedType: null,
      selectedScope: null,
      createDescription: null,
      createValue: null,

      currentBonus: null,
    };
  },
  async created() {
    if (
      this.profile &&
      this.profile.role == "User" &&
      this.profile.team != null
    ) {
      this.selectedTeam = await Team.getById(this.profile.team);
      let currentUnivers = await Univers.getById(this.selectedTeam.univers);
      this.selectedSession = await Session.getById(currentUnivers.session);

      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );
    } else {
      this.unsub.push(
        Session.listenOnlySession((sessions) => {
          this.sessions = sessions;
          sessions.sort((a, b) => b.date - a.date);
        })
      );
    }
  },
  methods: {
    async OnSessionSelected() {
      let sessionUniverses = await Univers.getBySession(
        this.selectedSession.id
      );
      let tmp_teams = [];
      this.teams = [];

      this.selectedPeriod = null;
      this.selectedTeam = null;

      this.currentBonus = null;

      sessionUniverses.forEach((univers) => {
        this.unsub.push(
          Team.listenByUnivers(univers.id, (teams) => {
            teams.forEach((team) => {
              tmp_teams.push(team);
            });
            tmp_teams.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.teams = tmp_teams;
          })
        );
      });
      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods;
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );
    },
    async OnSelection() {
      if (this.selectedTeam != null && this.selectedPeriod != null) {
        await this.loadBonus();
      }
    },
    async loadBonus() {
      this.unsub.push(
        Bonus.listenByPeriod(this.selectedPeriod.id, async (bonuses) => {
          if (bonuses.length > 0) {
            this.currentBonus = bonuses[0];
          } else {
            this.currentBonus = await Bonus.initializeOne(
              this.selectedPeriod.id
            );
            await this.currentBonus.save();
          }

          if (this.currentBonus.individual[this.selectedTeam.id] == undefined) {
            this.currentBonus.individual[this.selectedTeam.id] = [];
            this.$forceUpdate();
          }
        })
      );
    },
    openBonusDialog() {
      this.addBonusDialog = true;
      this.selectedScope = null;
      this.selectedType = null;
      this.createDescription = null;
      this.createValue = null;
    },
    addBonus() {
      if (
        this.selectedScope != null &&
        this.selectedType != null &&
        this.createDescription != null &&
        this.createValue != null &&
        parseFloat(this.createValue) > 0
      ) {
        if (this.selectedScope.type == "session") {
          this.currentBonus.global.push({
            type: this.selectedType,
            description: this.createDescription,
            value: this.createValue,
            scope: this.selectedScope.type,
          });
        } else {
          if (this.currentBonus.individual[this.selectedTeam.id] == undefined) {
            this.currentBonus.individual[this.selectedTeam.id] = [];
          }
          this.currentBonus.individual[this.selectedTeam.id].push({
            type: this.selectedType,
            description: this.createDescription,
            value: this.createValue,
            scope: this.selectedScope.type,
          });
        }
      }
      this.$forceUpdate();
      this.currentBonus.save();
      this.addBonusDialog = false;
    },
    deleteBonus(bonus) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez vous vraiment supprimer ce bonus ?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (bonus.scope == "session") {
            this.currentBonus.global = this.currentBonus.global.filter((b) => {
              return b != bonus;
            });
          } else {
            this.currentBonus.individual[this.selectedTeam.id] =
              this.currentBonus.individual[this.selectedTeam.id].filter((b) => {
                return b != bonus;
              });
          }
          this.currentBonus.save();
        }
      });
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
